import type {
	PaymentMethodBankPayload,
	PaymentMethodCardPayload,
	PaymentMethod,
	BankAccountApiResponse,
	CreditCardApiResponse,
	UpdateCardPayload
} from '@/components/account/paymentMethods/types'

import {
	API_BASE,
	API_ENDPOINT_ACCOUNT_PAYMENT_METHODS,
	API_ENDPOINT_ACCOUNT_AUTOPAY_ENABLE,
	API_ENDPOINT_ACCOUNT_AUTOPAY_REMOVE
} from '@/constants/index.js'
import { CREDIT_CARD, BANK_ACCOUNT } from '@/constants/payment'

import { useToast } from '@/composables/useToast'
import { useErrorReporter } from '@/composables/useErrorReporter'
import { useAuth } from '@/composables/useAuth'

import { useI18n } from 'vue-i18n'
import { ref, computed, watch } from 'vue'

const { addToast } = useToast()
const { bugsnagReport } = useErrorReporter()

const paymentMethods = ref<PaymentMethod[]>([])
const loading = ref(false)

const autopayPaymentMethod = computed(() => {
	return paymentMethods.value?.find(method => method.preferred)
})
const hasAutoPay = computed(() => {
	return autopayPaymentMethod.value
})
const autoPayLastFourDigits = computed(() => {
	return autopayPaymentMethod.value?.last4
})

export function usePaymentMethods () {
	const { accountId, emailId, fullName } = useAuth()
	const { t } = useI18n()

	const getPaymentMethodDisplayText = (method: PaymentMethod) => {
		return method.type === BANK_ACCOUNT
			? t('payment-methods.manage.bank-account-ending', { lastFour: method.last4 })
			: t('payment-methods.manage.credit-card-ending', { lastFour: method.last4 })
	}

	const getPaymentMethods = async (data?: { accountId: string, emailId: string }) => {
		loading.value = true

		let account: string, accountEmailId: string

		if (!data) { // if data is not provided, use the current user's account and email
			account = accountId?.value || ''
			accountEmailId = emailId?.value.toString() || ''
		} else { // if data is provided, use the provided account and email
			account = data.accountId
			accountEmailId = data.emailId
		}

		if (!account || !accountEmailId) {
			loading.value = false
			bugsnagReport({
				error: new Error('getPaymentMethods Error: Account or email ID not found'),
				showToast: true,
				user: { id: account, emailId: accountEmailId, name: fullName.value }
			})
			return
		}

		const fullPath = `${API_BASE}${API_ENDPOINT_ACCOUNT_PAYMENT_METHODS.replace('{account-id}', account).replace('{email-id}', accountEmailId)}`

		try {
			await $fetch(fullPath, {
				method: 'GET',
				credentials: 'include',
				server: false,
				onResponse ({ response }) {
					if (response.ok) { // Process successful response
						// clear existing payment methods
						paymentMethods.value = []
						const creditCards = response._data?.creditCards?.map((creditCard: CreditCardApiResponse): PaymentMethod => ({
							id: creditCard.creditCardId?.toString(),
							type: CREDIT_CARD,
							last4: creditCard.last4,
							expiration: `${creditCard.expiryMonth} / ${creditCard.expiryYear}`,
							preferred: creditCard.preferred
						}))
						const bankAccounts = response._data?.bankAccounts?.map((bankAccount: BankAccountApiResponse): PaymentMethod => ({
							id: bankAccount.bankAccountId?.toString(),
							type: BANK_ACCOUNT,
							last4: bankAccount.bankLast4,
							preferred: bankAccount.preferred
						}))
						if (creditCards) {
							paymentMethods.value.push(...creditCards)
						}
						if (bankAccounts) {
							paymentMethods.value.push(...bankAccounts)
						}
					} else {
						// Handle errors based on status code
						bugsnagReport({
							error: new Error('getPaymentMethods: Unable to retrieve payment methods'),
							showToast: true,
							user: { id: account, emailId: accountEmailId, name: fullName.value },
							metaData: response._data?.errors
						})
					}
				}
			})
		} catch (e) { // Catch block for handling errors not caught by onResponse
			// e.g., network issues or problems with the fetch request itself
			bugsnagReport({
				error: e instanceof Error ? e : new Error('getPaymentMethods - catch: ' + (e as Error).toString()),
				showToast: true,
				user: { id: account, emailId: accountEmailId, name: fullName.value }
			})
		} finally {
			loading.value = false
		}
	}

	watch(accountId, () => {
		getPaymentMethods()
	})

	const addPaymentMethod = async (paymentMethod: { bankAccount?: PaymentMethodBankPayload, creditCard?: PaymentMethodCardPayload }) => {
		loading.value = true
		const fullPath = `${API_BASE}${API_ENDPOINT_ACCOUNT_PAYMENT_METHODS.replace('{account-id}', accountId.value).replace('{email-id}', emailId.value?.toString())}`

		try {
			await $fetch(fullPath, {
				method: 'POST',
				credentials: 'include',
				body: paymentMethod,
				onResponse ({ response }) {
					if (!response.ok) {
						throw new Error('Unable to add payment method')
					}
					addToast({
						notificationType: 'success',
						title: 'Success!',
						message: 'Payment Method Added'
					})
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('addPaymentMethod - catch: ' + (e as Error).toString()),
				showToast: true,
				user: { id: accountId.value, emailId: emailId.value, name: fullName.value }
			})
		} finally {
			await getPaymentMethods()
			loading.value = false
		}
	}

	const updatePaymentMethod = async (body: UpdateCardPayload): Promise<{ success: boolean }> => {
		loading.value = true
		const fullPath = `${API_BASE}${API_ENDPOINT_ACCOUNT_PAYMENT_METHODS.replace('{account-id}', accountId.value).replace('{email-id}', emailId.value?.toString())}`
		const res = await $fetch(fullPath, {
			method: 'PUT',
			credentials: 'include',
			body: {
				...body,
				emailId: emailId.value
			},
			onResponse ({ response }) {
				if (!response.ok) {
					throw new Error(response._data?.errors?.[0]?.title || 'Unable to update payment method')
				}
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('addPaymentMethod - catch: ' + (e as Error).toString()),
				showToast: true,
				toast: {
					title: 'Error updating payment method',
					message: e?.message
				},
				user: { id: accountId.value, emailId: emailId.value, name: fullName.value }
			})
		}).finally(() => {
			getPaymentMethods()
			loading.value = false
		})
		return res as { success: boolean }
	}

	const removePaymentMethod = async (data: { accountId: string, emailId: string, paymentMethod: { bankAccountId?: string, creditCardId?: string } }): Promise<{ success: boolean }> => {
		loading.value = true

		const { accountId, emailId, paymentMethod } = data

		const fullPath = `${API_BASE}${API_ENDPOINT_ACCOUNT_PAYMENT_METHODS.replace('{account-id}', accountId).replace('{email-id}', emailId)}`

		const res = await $fetch(fullPath, {
			method: 'DELETE',
			credentials: 'include',
			body: paymentMethod,
			onResponse ({ response }) {
				if (!response?.ok) {
					throw new Error(response._data?.error || response._data?.errors?.[0]?.title || 'Unable to delete payment method')
				}
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('removePaymentMethod - catch: ' + (e as Error).toString()),
				showToast: true,
				toast: {
					title: 'Error removing payment method',
					message: e?.message
				},
				user: { id: accountId, emailId, name: fullName.value }
			})
		}).finally(async () => {
			await getPaymentMethods()
			loading.value = false
		})
		return res as { success: boolean }
	}

	const enableAutopay = async (data: { accountId: string, creditCardId: number | null, bankAccountId: number | null }): Promise<{ success: boolean }> => {
		loading.value = true
		const { accountId, creditCardId, bankAccountId } = data

		const fullPath = `${API_BASE}${API_ENDPOINT_ACCOUNT_AUTOPAY_ENABLE.replace('{account-id}', accountId)}`

		const res = await $fetch(fullPath, {
			method: 'PUT',
			credentials: 'include',
			body: {
				credit_card_id: creditCardId,
				bank_account_id: bankAccountId
			},
			onResponse ({ response }) {
				if (!response.ok) {
					throw new Error('Unable to set automatic payment method')
				}
			}
		}).catch((e) => {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('enableAutopay - catch: ' + (e as Error).toString()),
				showToast: true,
				user: { id: accountId, emailId: emailId.value, name: fullName.value }
			})
		}).finally(() => {
			getPaymentMethods()
			loading.value = false
		})
		return res as { success: boolean }
	}

	const removeAutopay = async (data: { accountId: string, creditCardId: number | null, bankAccountId: number | null }) => {
		loading.value = true

		const { accountId, creditCardId, bankAccountId } = data

		const fullPath = `${API_BASE}${API_ENDPOINT_ACCOUNT_AUTOPAY_REMOVE.replace('{account-id}', accountId)}`

		try {
			await $fetch(fullPath, {
				method: 'PUT',
				credentials: 'include',
				body: {
					credit_card_id: creditCardId,
					bank_account_id: bankAccountId
				},
				onResponse ({ response }) {
					if (!response.ok) {
						throw new Error('Unable to remove automatic payment method')
					}
					addToast({
						notificationType: 'success',
						title: 'Success!',
						message: 'Autopay Disabled'
					})
				}
			})
		} catch (e) {
			bugsnagReport({
				error: e instanceof Error ? e : new Error('removeAutopay - catch: ' + (e as Error).toString()),
				showToast: true,
				user: { id: accountId, emailId: emailId.value, name: fullName.value }
			})
		} finally {
			getPaymentMethods()
			loading.value = false
		}
	}

	return {
		getPaymentMethodDisplayText,
		getPaymentMethods,
		addPaymentMethod,
		removePaymentMethod,
		enableAutopay,
		removeAutopay,
		paymentMethods,
		loading,
		autopayPaymentMethod,
		hasAutoPay,
		autoPayLastFourDigits,
		updatePaymentMethod
	}
}
